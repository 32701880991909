@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@aws-amplify/ui-angular/theme.css";

div,
span,
a,
li,
h1,
h2,
h3 {
  color: #004231;
}

i{
  color: white;
}

$backgroundColor: #f3f3f3;
$ancoGreen: #004231;
$ancoYellow: #f7f410;
$ancoGrey: #8e8e8e;
$markerGrey: #e7e7e7;
$darkMarkerGrey: #cdcdcd;
$markedYellow: #ffe501;
$white: #ffffff;
$mandatory: #5bb8a2;
$errorRed: red;
$warningOrange: orange;
$modal-inner-padding: 1rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

$line-height-base: 1.5 !default;
$border-width: 1px !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($ancoGreen, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($ancoGreen, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($ancoGreen, 0.5) !default;

$modal-backdrop-bg: $ancoGreen !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $ancoGreen !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 1rem !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-transition: transform 0.3s ease-out !default;

.btn-ancotrans {
  background-color: #004231;
  color: #f7f410;
  margin-right: 5px;
}

.btn-ancotrans:hover {
  color: #f7f410;
  border: 1px solid #f7f410;
}

.error-text {
  color: red;
}

.page-link {
  color: #000000;
}

.page-item.active .page-link {
  background-color: #004231;
  color: #f7f410;
  border-color: #004231;
}

.page-link:hover {
  color: #004231;
}

.full-width {
  width: 100%;
}

.row-spacer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pointer {
  cursor: pointer;
}

.validator-error-text {
  color: red !important;
}

.no-padding-left {
  padding-left: 0px !important;
}

.no-padding-right {
  padding-right: 0px !important;
}

.header {
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 2px solid #c4c4c4;
  font-size: 18pt;
  margin-bottom: 10px;
}

.sub-header {
  font-weight: bold;
  padding-bottom: 5px;
  font-size: 14pt;
}

.top-spacer {
  padding-bottom: 20px;
}

.mat-form-field-appearance-outline
  .mat-form-field-outline
  .mat-form-field-outline-start,
.mat-form-field-appearance-outline
  .mat-form-field-outline
  .mat-form-field-outline-end {
  border-radius: 0;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  // dont touch option-select list visibility inside modals
  z-index: 99999 !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {padding:5px}

mat-form-field.mat-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix
  > span.mat-form-field-label-wrapper {
  top: -1.5em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}

.mat-form-field-wrapper {
  padding-bottom: 0.6rem;
}

.search-bar {
  margin-bottom: 15px;

  input {
    width: 500px;
    border-radius: 10px;
    padding: 5px;
    z-index: 2;
  }

  mat-form-field {
    width: 500px;
    border-radius: 10px;
    //padding: 5px;
    z-index: 2;
  }

  i {
    position: relative;
    z-index: 1;
    left: -25px;
  }
}

table {
  th {
  }

  .btn {
    width: 50px;
    margin-right: 10px;
  }
}

.table-spiner {
  position: relative;
  line-height: 200px;
}

.required-marker {
  color: red;
}

.hide-pagination {
  display: none;
}

.modal-body {
  padding: 17px 30px 24px 30px;
}

.modal-header {
  background-color: #d3d3d340;
  flex-direction: column;

  .close {
    position: absolute;
    right: 30px;
    outline: 0;
  }
}

.modal-subtitle {
  margin-top: 14px;
  color: $ancoGreen;
}

.modal-body {
  padding: 17px 30px 24px 30px;
}

.modal-footer {
  width: 100%;
  background-color: #d3d3d340;
  padding: 0px;
}

.modal-title {
  img {
    margin-right: 6px;
  }
}

.info-text {
  &-disabled {
    color: darkgrey;
  }
  font-weight: bold;
}

.label-text {
  font-size: 14px;
  color: $ancoGrey;
  font-weight: normal;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 99999;
}

@media (max-width: 900px) {
  .sticky-header {
    position: static;
  }
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: $ancoGreen !important;
 }

 td.mat-cell {
  padding: 15px !important;
 }
 th.mat-header-cell {
  padding: 15px !important;
 }

 #mapToggleContainer {
  position: absolute;
  z-index: 1;
}

#mapToggle {
  width: 80px;
  height: 32px;
  border-radius: 1%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: 1px solid black;
  cursor: pointer;
}

.warning-text {
  font-weight: bold;
  color: $warningOrange;
  background-color: #6a6a6a2a;
  padding: 10px;
}

.error-text {
  font-weight: bold;
  color: $errorRed;
  background-color: #cda9a92a;
  padding: 10px;
}
